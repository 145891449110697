<template>
  <div class="courseListBg">
    <div class="courseWid">
      <el-input placeholder="请输入内容" v-model="Courseinput" class="courseInput" @input="searchCourse">
        <template slot="append" >
          <span @click="searchCourse()">搜索</span>
        </template>
      </el-input>
    </div>
    <div class="couserlIstBg">
      <div class="courseListW">
        <div class="courseListTitle">
          <img src="images/courseIcon.png"/>
          <p>
            <span v-if="queryData.data=='Boutique'">精品体系课</span>
            <span v-if="queryData.data=='discount'">限时折扣</span>
            <span v-if="queryData.data=='famousTeacher'">名师公开课</span>
            <span v-if="queryData.data=='all'">全部课程</span>
<!--            <span>共</span>-->
<!--            <span>{{total}}</span>-->
<!--            <span>课程</span>-->
          </p>
        </div>
        <div class="coueClassfy" v-if="this.$route.query.data=='Boutique'">
          <span class="coueClassfyPx">分类:</span>
          <div>
            <span v-for="(item,index) in CourseClassifyArr" :key="index" @click="changeCourseClassfy(index,item)" class="courseClassfiy" :class="courseClassSHow==index?'courseClassActive':''">{{item.name}}</span>
          </div>
          <div>
<!--            <span class="courseClassfiy" v-for="(item,index) in CourseClassifyArr1" :key="index" @click="changeCourseClassfy1(index,item)"  :class="courseClassSHow==index?'courseClassActive':''">{{item.name}}</span>-->
<!--            <span class="courseClassfiy" v-if="!teacherMore">电子商务</span>-->
<!--            <span class="courseClassfiy" v-if="teacherMore">数字媒体</span>-->
          </div>
        </div>
        <div class="coursePx">
          <span class="coueClassfyPx">排序:</span>
          <span v-for="(item,index) in rankArr" :key="index" class="courseRank" @click="changeCourseRank(index)"  :class="courseRankSHow==index?'courseRankActive':''">{{item.name}}</span>
        </div>
        <div class="coursedifficultyPx">
          <div>
            <span class="coueClassfyPx">难度:</span>
            <span v-for="(item,index) in CourseDiffArr" :key="index" @click="chooseDiff(item.itemName,index)"class="courseRank" :class="courseDifficulty==index?'courseRankActive':''">{{item.itemName}}</span>
          </div>
          <div>
              <span>共</span>
              <span style="color: #409EFF;margin: 0 6px">{{total}}</span>
              <span>课程</span>
          </div>
        </div>
      </div>
      <div style="width: 100%;background: white;padding:50px 0">
        <div class="courserListWid">
          <div class="courserlIstFlex" >
            <div  class="courseBorder" v-if="courseListArrlist.length" v-for="(item,index) in courseListArrlist" :key="index" @click="goCourserDetail(item)">
              <img class="courseBorderImgs" :src="item.logo" />
              <div class="courserTitles">
                <p class="courserTitlesP1">{{ item.name }}</p>
                <p class="courserTitlesP2" v-if="item.teacherUser&&Object.keys(item.teacherUser).length">{{ item.teacherUser.name }}</p>
              </div>
            </div>
            <div v-if="!courseListArrlist.length" style="width: 100%">
              <el-empty :image-size="200"></el-empty>
            </div>
          </div>
          <div class="">
            <el-pagination
                class="text_center"
                background
                @current-change="handleCourseList"
                :current-page.sync="currentPage"
                :page-size="size"
                layout="total,  prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {courseClassify,courseDifficulty,courseList} from "@/api";

  export default {
    data(){
      return{
        rankArr:[
          {id:1,name:'综合'},
          {id:2,name:'学习人数'},
          {id:3,name:'发布时间'},
        ],
        courseClassSHow:0,
        courseRankSHow:0,
        courseDifficulty:0,
        courseListArrlist:[],
        Courseinput:'',
        CourseClassifyArr:[],
        CourseClassifyArr1:[
          {id:1,name:'全部'},
          {id:2,name:'电子商务'},
        ],
        CourseDiffArr:[],
        courseListArr:[],
        courseClassifyName:'',
        currentPage: 1, //初始页
        size: 20,
        total: 0,
        nanyiDu:'',
        courseClassifyId:"",
        queryData:{},
        courseQuery:'',
        allCourse:'',
        teacherMore:''
      }
    },
    methods:{
    //  分类
      changeCourseClassfy(index,item){
        this.courseClassSHow=index;
        // this.courseClassifyName=item.name;
        this.courseClassifyId=item.id
        this.getCourseList()
      },
      //分类第二种
      // changeCourseClassfy1(index,item){
      //   this.courseClassSHow=index;
      //   this.allCourse=item.name
      //   this.getCourseList()
      // },
      //选择难易度
      chooseDiff(name,index){
        this.courseDifficulty=index
        this.nanyiDu=name;
        this.getCourseList();
      },
      //排序
      changeCourseRank(index){
        this.courseRankSHow=index;
        if(index!==3){
          this.getCourseList()
        }
      },
      //课程详情
      goCourserDetail(item){
        if(item.forEnterprise=='0'){
          sessionStorage.setItem('courseDetail',JSON.stringify(item));
          let routeUrl = this.$router.resolve({
            path: "/courseDetail",
          });
          window.open(routeUrl.href);
        }else{
          sessionStorage.setItem('courseVideoDetails',JSON.stringify(item) )
          let routeUrl = this.$router.resolve({
            path: "/courseVideoDetais",
          });
          window.open(routeUrl.href);
        }
      },
      //课程列表
      getCourseClassify(){
        courseClassify().then(res => {
          let courseClassSify=res.data
          // this.CourseClassifyArr=res.data;
          // let arr=[
          //   {name:'全部'}
          // ]
          // courseClassSify.forEach(item=>{
          //   arr.push(item)
          // })
          this.CourseClassifyArr=courseClassSify;
          this.getCourseList(courseClassSify[0].id)
          // this.courseClassifyId=courseClassSify[0].id
        })
      },
    //  课程难易度
      getCourseDifficulty(){
        let data={
          categoryCode:'course-facility-value'
        }
        courseDifficulty(data).then(res => {
          let arr=[
            {itemName:'全部'}
          ];
          res.data.records.forEach(item=>{
            arr.push(item)
          })
          this.CourseDiffArr=arr
        })
      },
      //搜索课程
      searchCourse(){
        this.getCourseList();
      },
      //获取课程列表
      getCourseList(id){
        let data={
          page:this.currentPage,
          size:this.size
        };

        if(this.$route.query.courseQuery){
          data['name']=this.$route.query.courseQuery
        }
        if(this.Courseinput){
          data['name']=this.Courseinput
        }
        // if(this.courseClassifyName){
        //   data['courseClassifyName']=this.courseClassifyName
        // }
        if(this.allCourse){
          data['name']=''
        }
        if(this.$route.query.data=='discount'){
          data['activityType']='discount'
        }
        if(this.$route.query.data=='Boutique'){
          data['labels']='精品体系课';
          data['courseClassifyId']=id?id:this.courseClassifyId
        }
        if(this.$route.query.data=='famousTeacher'){
          data['labels']='名师公开课'
        }
        if(this.courseRankSHow&&this.courseRankSHow==1){
          data['queryOrderItem']='create_time'
        }
        if(this.courseRankSHow&&this.courseRankSHow==2){
          data['queryOrderItem']='study_people_number'
        }
        if(this.nanyiDu&&this.nanyiDu!=='全部'){
          data['difficultyLevel']=this.nanyiDu
        }else{
          data['difficultyLevel']=''
        }
        // if(this.courseClassSHow==0){
        //   data['courseClassifyId']='';
        //   data['courseClassifyName']=''
        // }
        courseList(data).then(res => {
          if(res.code==0){
            this.courseListArrlist=res.data.records;
            this.total = JSON.parse(res.data.total) ;
          }

        })
      },
      // 分页
      handleCourseList: function (currentPage) {
        this.currentPage = currentPage;
        this.getCourseList();
      },
    },

    mounted() {
      document.documentElement.scrollTop = 0;
      this.courseQuery=this.$route.query.courseQuery
      this.$other.$emit('setIndexNav',-1);
      this.queryData=this.$route.query
      this.Courseinput=this.$route.query.searchName
      this.getCourseClassify();
      this.getCourseDifficulty();
      // this.getCourseList();
      if(this.$route.query.teacherMore){
        this.teacherMore=this.$route.query.teacherMore
      }

    }
  }
</script>
<style scoped>
@import "../../../public/css/courseList.css";
</style>
